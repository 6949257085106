import React, { useEffect, useState } from 'react';

// material-ui
import { Button, Grid, CardContent, TextField } from '@mui/material';

// project imports
import MainCard from 'ui-component/cards/MainCard';

// assets
import Loader from 'ui-component/Loader';
import Add from './Add';
import { useDispatch } from 'react-redux';
import { openSnackbar } from 'store/slices/snackbar';
import useAuth from 'hooks/useAuth';
import axiosServices from 'utils/axios';
import InfoModal from 'ui-component/InfoModal';
import { CopyAllOutlined } from '@mui/icons-material';

const CreatePayin = () => {
    const dispatch = useDispatch();
    const { user } = useAuth();

    const [loading, setLoading] = React.useState(false);
    const [copy, setCopy] = React.useState(false);

    const [open, setOpen] = React.useState(false);
    const [forceUpdate, setForceUpdate] = React.useState(false);
    const [data, setData] = React.useState(false);

    useEffect(() => {
        if (user && user?.role === 'MERCHANT_USER' && !user?.isSinglePayoutEnable) {
            window.location.href = '/';
        }
    }, [user]);

    React.useEffect(() => {
        if (copy) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: 'Copied!',
                    variant: 'alert',
                    alert: {
                        color: 'success'
                    },
                    close: false
                })
            );
            setCopy(false);
        }
    }, [copy]);

    const handleCloseDialog = (data) => {
        if (data) {
            setData(data);
        }
        setOpen(false);
    };

    useEffect(() => {
        axiosServices.get('getUsers/forceUpdateUser/byToken').then((r) => {
            setForceUpdate(r?.data?.data?.isForceUpdated);
        });
    }, [open]);

    return (
        <MainCard
            title={
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div>Payin Transaction</div>
                </div>
            }
        >
            {loading && <Loader />}
            <CardContent>
                <Grid container alignItems="center" spacing={1}>
                    {/* <Grid item>
                        <Autocomplete
                            id="merchant-select"
                            options={merchants || []}
                            onChange={(e, value) => {
                                setSelectedMerchant(value);
                            }}
                            sx={{ width: 200 }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    size="small"
                                    onChange={(e, value) => {
                                        setSelectedMerchant(value);
                                    }}
                                    label="Merchants"
                                />
                            )}
                        />
                    </Grid> */}
                    <Grid item>
                        <Button
                            style={{ marginLeft: 20 }}
                            size="small"
                            variant="contained"
                            onClick={() => {
                                if (forceUpdate) window.location.reload();
                                setOpen(true);
                            }}
                        >
                            Create Payin Transaction
                        </Button>
                    </Grid>
                </Grid>
                <Add open={open} handleCloseDialog={handleCloseDialog} />
                <InfoModal
                    open={data}
                    title="Response"
                    subTitle={
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}
                        >
                            <div>{data?.checkout_url}</div>
                            <div
                                role="presentation"
                                onKeyDown={() => {}}
                                onClick={() => {
                                    navigator.clipboard.writeText(data?.checkout_url);
                                    dispatch(
                                        openSnackbar({
                                            open: true,
                                            message: 'Copied!',
                                            variant: 'alert',
                                            alert: {
                                                color: 'success'
                                            },
                                            close: false
                                        })
                                    );
                                }}
                            >
                                <CopyAllOutlined />
                            </div>
                        </div>
                    }
                    handleClose={() => setData(false)}
                />
            </CardContent>
        </MainCard>
    );
};

export default CreatePayin;
